<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'ProductDiscountsList'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>


    <div class="w-full">

      <div class="vx-row w-full" v-if="Object.keys(discountsData).length > 0">
        <div
          class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">

          <reclaim-card
            :text="getDiscountText(discountsData.direct_recharge_discount)"
            :title="$t('DirectRechargeDiscount')"
            color="white"
            text-color="white"
            bg-color="danger"
            type="area"/>
        </div>
        <div
          class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">

          <reclaim-card
            :text="getDiscountText(discountsData.dt_one_discount)"
            :title="$t('InternationalTopUpDiscount')"
            color="white"
            text-color="white"
            bg-color="warning"
            type="area"/>
        </div>
        <div
          class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">

          <reclaim-card
            :text="getDiscountText(discountsData.flixbus_discount)"
            :title="$t('FlixBusDiscount')"
            color="white"
            text-color="white"
            bg-color="primary"
            type="area"/>
        </div>
      </div>

      <div class="vs-row w-full" v-if="Object.keys(discountsData).length > 0">
        <div class="w-full md:w-1/2 vs-col">
          <p class="text-2xl font-bold text-center py-2 pdl-title">{{ $t('CashCards') }}</p>
          <vs-table-modified v-show="discountsData.cash_cards"
                             :data="discountsData.cash_cards">
            <template slot="thead">
              <vs-th >{{ $t('Product') }}</vs-th>
              <vs-th >{{ $t('Denomination') }}</vs-th>
              <vs-th >{{ $t('Discount') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate"
                  >{{ tr.product }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.denomination | germanNumberFormat }}</p>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.discount | justNumberFormatGerman}} %</p>
                </vs-td>
              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
            </template>
          </vs-table-modified>
        </div>
        <div class="w-full md:w-1/2 px-3 vs-col">
          <p class="text-2xl font-bold text-center py-2 pdl-title">{{ $t('GiftCards') }}</p>
          <vs-table-modified v-show="discountsData.gift_cards"
                             :data="discountsData.gift_cards">
            <template slot="thead">
              <vs-th >{{ $t('Product') }}</vs-th>
              <vs-th >{{ $t('Denomination') }}</vs-th>
              <vs-th >{{ $t('Discount') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate"
                  >{{ tr.product }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.denomination | germanNumberFormat }}</p>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.discount | justNumberFormatGerman}} %</p>
                </vs-td>
              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">

            </template>
          </vs-table-modified>
        </div>
        <div class="w-full md:w-1/2 px-3 vs-col">
          <p class="text-2xl font-bold text-center py-2 pdl-title">{{ $t('CallingCards') }}</p>
          <vs-table-modified v-show="discountsData.calling_cards"
                             :data="discountsData.calling_cards">
            <template slot="thead">
              <vs-th >{{ $t('Product') }}</vs-th>
              <vs-th >{{ $t('Denomination') }}</vs-th>
              <vs-th >{{ $t('Discount') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate"
                  >{{ tr.product }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.denomination | germanNumberFormat }}</p>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.discount | justNumberFormatGerman}} %</p>
                </vs-td>
              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">

            </template>
          </vs-table-modified>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ReclaimCard from "@/views/components/ReclaimCard";
export default {
  name: "ProductDiscountList",
  components: {ReclaimCard},
  data() {
    return {
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Product Discounts List', i18n: 'ProductDiscountsList', active: true},
      ],
    }
  },
  computed: {
    discountsData() {
     return  this.$store.state.productDiscountList.products;
    }
  },
  methods: {
    getDiscountText(n) {
      return `${n.toString().replace(".", ",")} %`
    },
    fetchProducts(page = '0', filters = {}) {
      const payload = {
        page: page,
      }

      this.$vs.loading()
      return this.$store.dispatch('productDiscountList/fetchProductDiscountsList', {payload})
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchProducts()
  }
}
</script>

<style scoped>

</style>
